import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { Gift, FileText, Layout, Briefcase, MessageSquarePlus } from "lucide-react";
import { motion } from "framer-motion";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useNavigate } from "react-router-dom";

const Referrals = () => {
  const navigate = useNavigate();
  const referralTiers = [
    {
      name: "CV Website",
      price: "$99",
      commission: "$20",
      icon: <FileText className="w-5 h-5" />,
      gradient: "from-[#D3E4FD] via-[#33C3F0] to-[#0EA5E9]"
    },
    {
      name: "Landing Page",
      price: "$179",
      commission: "$40",
      icon: <Layout className="w-5 h-5" />,
      gradient: "from-[#8B5CF6] via-[#D946EF] to-[#F97316]"
    },
    {
      name: "Professional",
      price: "$249",
      commission: "$70",
      icon: <Briefcase className="w-5 h-5" />,
      gradient: "from-[#7EBF8E] via-[#61AAF2] to-[#8989DE]"
    },
    {
      name: "Bespoke",
      price: "Quote",
      commission: "10%",
      icon: <MessageSquarePlus className="w-5 h-5" />,
      gradient: "from-[#F97316] via-[#D946EF] to-[#8B5CF6]"
    }
  ];

  return (
    <div className="min-h-screen bg-neutral-100">
      <Navigation />
      <div className="container mx-auto px-4 pt-32 pb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <div className="inline-flex items-center justify-center p-3 mb-6 rounded-full bg-gradient-to-r from-[#D3E4FD] via-[#33C3F0] to-[#0EA5E9]">
            <Gift className="w-8 h-8 text-white" />
          </div>
          <h1 className="text-4xl font-bold mb-4 font-helvetica">Referral Program</h1>
          <p className="text-neutral-600 text-lg max-w-2xl mx-auto">
            Share heyhalo with your network and earn rewards for every successful referral
          </p>
        </motion.div>

        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-xl shadow-lg p-8 mb-8"
          >
            <h2 className="text-2xl font-bold mb-6 font-helvetica">Commission Rates</h2>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Package</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Your Commission</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {referralTiers.map((tier) => (
                  <TableRow key={tier.name}>
                    <TableCell className="flex items-center gap-2">
                      <div className={`p-2 rounded-lg bg-gradient-to-br ${tier.gradient}`}>
                        {tier.icon}
                      </div>
                      {tier.name}
                    </TableCell>
                    <TableCell>{tier.price}</TableCell>
                    <TableCell>{tier.commission}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-xl font-bold mb-4 font-helvetica">How It Works</h3>
              <ul className="space-y-4 text-neutral-600">
                <li className="flex items-start gap-2">
                  1. Get your unique referral code
                </li>
                <li className="flex items-start gap-2">
                  2. Share your code with your network
                </li>
                <li className="flex items-start gap-2">
                  3. Earn commissions for successful referrals
                </li>
                <li className="flex items-start gap-2">
                  4. Your code can be used unlimited times
                </li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-xl font-bold mb-4 font-helvetica">Payment Terms</h3>
              <ul className="space-y-4 text-neutral-600">
                <li className="flex items-start gap-2">
                  • Payments are made monthly to your nominated Australian bank account
                </li>
                <li className="flex items-start gap-2">
                  • Payment date is the 5th of each month
                </li>
                <li className="flex items-start gap-2">
                  • Purchases made within the last 7 days of the month will be paid in the following month
                </li>
                <li className="flex items-start gap-2">
                  • Valid referral code must be used at time of purchase
                </li>
              </ul>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5 }}
            className="mt-12 text-center"
          >
            <button 
              onClick={() => navigate('/contact')}
              className="px-8 py-4 bg-gradient-to-r from-[#8B5CF6] via-[#D946EF] to-[#F97316] text-white rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105"
            >
              Get Your Referral Code
            </button>
          </motion.div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Referrals;
