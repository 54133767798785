import Navigation from "@/components/landing/Navigation";
import Hero from "@/components/landing/Hero";
import Footer from "@/components/landing/Footer";
import TaskProviderSection from "@/components/landing/TaskProviderSection";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div className="min-h-screen bg-background">
      <Helmet>
        <title>nowyesterday, i want it, now - coming soon</title>
        <meta name="description" content="create your perfect landing page through natural conversation with our ai assistant" />
      </Helmet>
      <Navigation />
      <Hero />
      <TaskProviderSection />
      <Footer />
    </div>
  );
};

export default Index;
