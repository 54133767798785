import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { Helmet } from "react-helmet";

const Features = () => {
  return (
    <div className="min-h-screen bg-neutral-100">
      <Helmet>
        <title>Features | heyhalo</title>
        <meta name="description" content="Explore heyhalo's powerful features" />
      </Helmet>
      <Navigation />
      
      <main className="container-padding pt-32 pb-16">
        <div className="max-w-3xl mx-auto">
          <div className="glass-panel rounded-2xl p-8 md:p-12">
            <h1 className="heading-xl bg-gradient-to-r from-[#8B5CF6] via-[#D946EF] to-[#F97316] 
                         bg-clip-text text-transparent animate-gradient bg-[length:200%_auto]"
              style={{
                animation: "gradient 3s linear infinite"
              }}>
              Features
            </h1>
            <p className="text-neutral-600 mt-4">Coming soon...</p>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Features;