import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from "lucide-react";

const contactMethods = [
  { id: "email", label: "email" },
  { id: "phone", label: "phone" },
  { id: "form", label: "contact form" },
];

export const SocialMediaFields = ({ form }: { form: any }) => {
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">social media links</h3>
        <div className="grid gap-4 sm:grid-cols-2">
          <FormField
            control={form.control}
            name="facebook"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center gap-2">
                  <Facebook className="w-4 h-4" /> facebook
                </FormLabel>
                <FormControl>
                  <Input placeholder="facebook url" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="instagram"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center gap-2">
                  <Instagram className="w-4 h-4" /> instagram
                </FormLabel>
                <FormControl>
                  <Input placeholder="instagram url" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="linkedin"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center gap-2">
                  <Linkedin className="w-4 h-4" /> linkedin
                </FormLabel>
                <FormControl>
                  <Input placeholder="linkedin url" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="twitter"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center gap-2">
                  <Twitter className="w-4 h-4" /> twitter
                </FormLabel>
                <FormControl>
                  <Input placeholder="twitter url" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="text-lg font-semibold">contact preferences</h3>
        <FormField
          control={form.control}
          name="contactMethods"
          render={() => (
            <FormItem>
              <div className="grid gap-4 sm:grid-cols-3">
                {contactMethods.map((method) => (
                  <FormField
                    key={method.id}
                    control={form.control}
                    name="contactMethods"
                    render={({ field }) => {
                      return (
                        <FormItem
                          key={method.id}
                          className="flex flex-row items-start space-x-3 space-y-0"
                        >
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(method.id)}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? field.onChange([...(field.value || []), method.id])
                                  : field.onChange(
                                      field.value?.filter((value: string) => value !== method.id)
                                    );
                              }}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">{method.label}</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                ))}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};