import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Leaf, FileText, Globe, Brush, Truck, MessageSquarePlus } from "lucide-react";

const serviceTypes = [
  {
    value: "gardening",
    label: "Gardening & Landscaping",
    icon: <Leaf className="w-4 h-4" />,
    description: "Garden maintenance, landscaping, lawn care"
  },
  {
    value: "resume",
    label: "Resume Writing & CV",
    icon: <FileText className="w-4 h-4" />,
    description: "Professional CV writing and career documents"
  },
  {
    value: "website",
    label: "Website Development",
    icon: <Globe className="w-4 h-4" />,
    description: "Custom websites and web applications"
  },
  {
    value: "cleaning",
    label: "House Cleaning",
    icon: <Brush className="w-4 h-4" />,
    description: "Home and office cleaning services"
  },
  {
    value: "moving",
    label: "Moving & Delivery",
    icon: <Truck className="w-4 h-4" />,
    description: "Relocation and delivery services"
  },
  {
    value: "other",
    label: "Other Services",
    icon: <MessageSquarePlus className="w-4 h-4" />,
    description: "Tell us about your specific needs"
  }
];

export const WebsiteTypeField = ({ form }: { form: any }) => {
  return (
    <FormField
      control={form.control}
      name="serviceType"
      render={({ field }) => (
        <FormItem className="space-y-3">
          <FormLabel className="text-lg font-bold">what do you need help with?</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
            >
              {serviceTypes.map((type) => (
                <FormItem key={type.value} className="relative">
                  <FormControl>
                    <RadioGroupItem
                      value={type.value}
                      className="peer sr-only"
                      id={`service-type-${type.value}`}
                    />
                  </FormControl>
                  <label
                    htmlFor={`service-type-${type.value}`}
                    className="flex flex-col p-4 rounded-lg border-2 cursor-pointer peer-checked:border-purple-500 peer-checked:bg-purple-50 hover:bg-gray-50 transition-all"
                  >
                    <div className="flex items-center gap-2">
                      <div className="p-1.5 rounded-lg bg-gradient-to-r from-[#9b87f5] to-[#D946EF] text-white">
                        {type.icon}
                      </div>
                      <div>
                        <p className="font-semibold">{type.label}</p>
                        <p className="text-sm text-gray-500">{type.description}</p>
                      </div>
                    </div>
                  </label>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};