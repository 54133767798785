import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { ContactForm } from "@/components/contact/ContactForm";

const Contact = () => {
  return (
    <div className="min-h-screen bg-[#FEF7CD]">
      <Helmet>
        <title>Contact Us | NowYesterday</title>
        <meta name="description" content="Get in touch with the NowYesterday team" />
      </Helmet>
      <Navigation />
      
      <main className="pt-32 pb-16">
        <div className="container-padding max-w-3xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 md:p-12 shadow-lg border border-black/10"
          >
            <h1 className="text-3xl font-bold mb-2 font-helvetica text-[#222222]">Get in Touch</h1>
            <p className="text-[#222222]/80 mb-8">
              Have questions about NowYesterday? We'd love to hear from you. Send us a message
              and we'll respond as soon as possible.
            </p>

            <ContactForm />
          </motion.div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;