import Navigation from "@/components/landing/Navigation";
import { motion } from "framer-motion";
import ServiceProviderForm from "@/components/forms/ServiceProviderForm";
import { useEffect } from "react";

const Chat = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <div className="container mx-auto px-4 py-24 max-w-2xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg border border-white/20"
        >
          <h1 className="text-3xl font-bold mb-6 text-secondary">
            i want extra work
          </h1>
          <p className="text-secondary/80 mb-8">
            let us know what services you can provide to help others in your community.
          </p>

          <ServiceProviderForm />
        </motion.div>
      </div>
    </div>
  );
};

export default Chat;