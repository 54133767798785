import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "sonner";
import { Form } from "@/components/ui/form";
import { motion } from "framer-motion";
import { ContactFields } from "@/components/forms/ContactFields";
import { InquiryTypeSelector } from "./InquiryTypeSelector";
import { PersonalInfoFields } from "./PersonalInfoFields";

const formSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters"),
  email: z.string().email("Please enter a valid email address"),
  message: z.string().min(10, "Message must be at least 10 characters"),
  inquiryTypes: z.array(z.string()).min(1, "Please select at least one inquiry type"),
});

export const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      message: "",
      inquiryTypes: [],
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsSubmitting(true);
    try {
      console.log("Form submitted:", values);
      toast.success("Message sent successfully! We'll get back to you soon.");
      form.reset();
    } catch (error) {
      toast.error("Failed to send message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <InquiryTypeSelector form={form} />
        <PersonalInfoFields form={form} />
        <motion.button
          type="submit"
          className="w-full px-6 py-3 bg-gradient-to-r from-[#9b87f5] via-[#D946EF] to-[#F97316] 
                   text-white rounded-xl font-medium shadow-lg hover:shadow-xl 
                   transition-all duration-300 hover:scale-[1.02]"
          disabled={isSubmitting}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          {isSubmitting ? "Sending..." : "Send Message"}
        </motion.button>
      </form>
    </Form>
  );
};