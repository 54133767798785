import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <div className="min-h-screen bg-neutral-100">
      <Helmet>
        <title>Privacy Policy | NowYesterday</title>
        <meta name="description" content="NowYesterday privacy policy" />
      </Helmet>
      <Navigation />
      
      <main className="container mx-auto px-4 pt-32 pb-16">
        <h1 className="heading-lg mb-8">Privacy Policy</h1>
        <div className="prose prose-lg max-w-none">
          <p>Coming soon...</p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Privacy;