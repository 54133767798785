import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { Helmet } from "react-helmet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const Questions = () => {
  return (
    <div className="min-h-screen bg-neutral-100">
      <Helmet>
        <title>FAQ | heyhalo</title>
        <meta name="description" content="Frequently asked questions about heyhalo" />
      </Helmet>
      <Navigation />
      
      <main className="container-padding pt-32 pb-16">
        <div className="max-w-4xl mx-auto">
          <div className="glass-panel rounded-2xl p-8 md:p-12">
            <h1 className="heading-xl bg-gradient-to-r from-[#8B5CF6] via-[#D946EF] to-[#F97316] 
                         bg-clip-text text-transparent animate-gradient bg-[length:200%_auto] mb-8"
              style={{
                animation: "gradient 3s linear infinite"
              }}>
              Frequently Asked Questions
            </h1>

            <Tabs defaultValue="process" className="w-full">
              <TabsList className="mb-8 bg-transparent space-x-2 w-full justify-start">
                <TabsTrigger 
                  value="process"
                  className="data-[state=active]:bg-accent-purple data-[state=active]:text-white"
                >
                  Our Process
                </TabsTrigger>
                <TabsTrigger 
                  value="websites"
                  className="data-[state=active]:bg-accent-purple data-[state=active]:text-white"
                >
                  Website Types
                </TabsTrigger>
                <TabsTrigger 
                  value="support"
                  className="data-[state=active]:bg-accent-purple data-[state=active]:text-white"
                >
                  Support
                </TabsTrigger>
                <TabsTrigger 
                  value="design-changes"
                  className="data-[state=active]:bg-accent-purple data-[state=active]:text-white"
                >
                  Design Changes
                </TabsTrigger>
              </TabsList>

              <TabsContent value="process">
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-1">
                    <AccordionTrigger className="text-lg font-medium">
                      What makes heyhalo unique?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      heyhalo combines the power of AI with human expertise. While our AI technology streamlines the website creation process, every website is reviewed and refined by our professional team before delivery. This unique blend ensures you get both the efficiency of AI and the quality assurance of human craftsmanship.
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-2">
                    <AccordionTrigger className="text-lg font-medium">
                      How does the website creation process work?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      Our process combines AI efficiency with human expertise: First, our AI assistant guides you through key questions about your needs and preferences. Then, it generates a customized website based on your responses. Finally, our human team reviews and refines the website, ensuring everything meets our high-quality standards before delivery to you.
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-3">
                    <AccordionTrigger className="text-lg font-medium">
                      How long does it take to create a website?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      The initial AI-guided process takes just 15-20 minutes. Our human team then reviews and refines your website within 24-48 hours, ensuring everything is perfect before delivery. This hybrid approach gives you the best of both worlds - quick creation and professional quality.
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </TabsContent>

              <TabsContent value="websites">
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-4">
                    <AccordionTrigger className="text-lg font-medium">
                      What types of websites can I create?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      You can create various types of websites including NDIS provider pages, Airbnb listings, professional CVs/portfolios, and more. Each template is optimized for its specific purpose and reviewed by our team to ensure it meets industry standards.
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-5">
                    <AccordionTrigger className="text-lg font-medium">
                      Can I edit my website after it's created?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      Yes! You can edit your website at any time. Our platform makes it easy to update content and make adjustments. Plus, our team is always available to help ensure your changes maintain the professional quality of your site.
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </TabsContent>

              <TabsContent value="support">
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-6">
                    <AccordionTrigger className="text-lg font-medium">
                      What support is available if I need help?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      We offer comprehensive support through both our AI chat system and our human support team. Whether you need technical assistance or design advice, we're here to help you create and maintain the perfect website for your needs.
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-7">
                    <AccordionTrigger className="text-lg font-medium">
                      How do you ensure quality?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      While our AI creates the initial design based on your requirements, our professional team reviews every website before delivery. This human touch ensures that your website not only meets technical standards but also provides the best possible user experience and professional presentation.
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </TabsContent>

              <TabsContent value="design-changes">
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-8">
                    <AccordionTrigger className="text-lg font-medium">
                      How does the design change process work?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      We offer a one-time complimentary design change to ensure your complete satisfaction. After your website is delivered, you can submit detailed feedback through our feedback form, and our team will make the necessary adjustments to match your vision while maintaining professional standards.
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-9">
                    <AccordionTrigger className="text-lg font-medium">
                      What should I include in my design change request?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      To help us implement your changes effectively, please be specific about the elements you'd like to modify. This could include color preferences, layout adjustments, or content updates. The more detailed your feedback, the better we can align the design with your expectations.
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-10">
                    <AccordionTrigger className="text-lg font-medium">
                      How long do design changes take?
                    </AccordionTrigger>
                    <AccordionContent className="text-neutral-600">
                      Once you submit your design change request through our feedback form, our team will review and implement the changes within 48-72 hours. We'll notify you once the changes are complete and ready for your review.
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Questions;
