import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

const TaskProviderSection = () => {
  const navigate = useNavigate();

  return (
    <section className="py-16 bg-background">
      <div className="container max-w-4xl mx-auto px-4">
        <div className="bg-white rounded-xl p-8 shadow-lg">
          <h2 className="text-3xl font-bold text-foreground mb-8 text-center">tell us your skills & work with us!</h2>
          <div className="space-y-4 text-foreground">
            <h3 className="text-xl font-semibold mb-4">what we're looking for:</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>skilled professionals in various fields</li>
              <li>reliable phone communication</li>
              <li>experience in your area of expertise</li>
              <li>commitment to quality service</li>
            </ul>
            <div className="mt-8 text-center">
              <Button 
                onClick={() => navigate('/chat')}
                className="bg-background text-secondary hover:bg-background/90 border border-secondary px-8 py-4"
              >
                register your interest
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TaskProviderSection;