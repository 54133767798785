// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://ctakxlcpksmjlmsxotdh.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN0YWt4bGNwa3Ntamxtc3hvdGRoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM4OTkwNzEsImV4cCI6MjA0OTQ3NTA3MX0.mjMf7Buw9uBLpc92Ro2z1ljxcQdOFod6lvXPAp8zMPI";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);