import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { ArrowRight, Bot, Users, Sparkles } from "lucide-react";

const About = () => {
  const scrollingText = "AI + Human collaboration • Professional design • Stunning results • ";

  return (
    <div className="min-h-screen bg-neutral-100">
      <Helmet>
        <title>About | NowYesterday</title>
        <meta name="description" content="Learn more about heyhalo and our mission to make website creation accessible to everyone" />
      </Helmet>
      <Navigation />
      
      <main className="container-padding pt-32 pb-16">
        <div className="max-w-4xl mx-auto">
          {/* Scrolling Text Banner */}
          <div className="w-full overflow-hidden bg-accent-purple py-4 text-white relative rounded-xl mb-8">
            <div className="animate-marquee whitespace-nowrap">
              {[...Array(3)].map((_, i) => (
                <span key={i} className="mx-4 text-lg font-medium inline-block">
                  {scrollingText}
                </span>
              ))}
            </div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="glass-panel rounded-2xl p-8 md:p-12 space-y-12"
          >
            {/* Hero Section */}
            <div className="space-y-6">
              <motion.h1 
                className="heading-xl bg-gradient-to-r from-[#8B5CF6] via-[#D946EF] to-[#F97316] 
                           bg-clip-text text-transparent animate-gradient bg-[length:200%_auto]"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                NowYesterday, get it done now!
              </motion.h1>
              <motion.p 
                className="text-2xl text-neutral-600"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                We're on a mission to make website creation accessible to everyone.
              </motion.p>
            </div>

            {/* Features Grid */}
            <div className="grid md:grid-cols-2 gap-8">
              {[
                {
                  title: "AI + Human Collaboration",
                  content: "heyhalo combines the power of AI with human expertise. Our AI assistant helps streamline the process while our team ensures every website meets professional standards. This unique collaboration delivers the best of both worlds - efficiency and quality.",
                  icon: <Bot className="w-6 h-6 text-accent-purple" />
                },
                {
                  title: "Professional Touch",
                  content: "Every website created through heyhalo benefits from professional oversight. While our AI handles the technical aspects, our human team ensures your website maintains high design standards and effectively represents your brand.",
                  icon: <Users className="w-6 h-6 text-accent-blue" />
                }
              ].map((section, index) => (
                <motion.div
                  key={section.title}
                  initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.5 }}
                  className="glass-panel p-6 rounded-xl hover:scale-105 transition-all duration-300"
                >
                  <div className="flex items-center gap-3 mb-4">
                    {section.icon}
                    <h2 className="text-2xl font-bold bg-gradient-to-r from-accent-purple to-accent-blue bg-clip-text text-transparent">
                      {section.title}
                    </h2>
                  </div>
                  <p className="text-neutral-600 leading-relaxed">
                    {section.content}
                  </p>
                </motion.div>
              ))}
            </div>

            {/* Promise Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="space-y-6"
            >
              <div className="flex items-center gap-3">
                <Sparkles className="w-6 h-6 text-accent-green" />
                <h2 className="text-2xl font-bold bg-gradient-to-r from-accent-blue to-accent-green bg-clip-text text-transparent">
                  Our Promise
                </h2>
              </div>
              <p className="text-neutral-600 leading-relaxed">
                We believe in making the web more accessible and beautiful, one website at a time. 
                Our platform combines AI efficiency with human creativity, ensuring that your 
                online presence stands out while staying true to your vision.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="pt-6"
            >
              <a 
                href="/chat" 
                className="button-primary inline-flex items-center group"
              >
                Start Creating Your Website
                <ArrowRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </motion.div>
          </motion.div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default About;