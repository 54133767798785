import { Helmet } from "react-helmet";
import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { toast } from "sonner";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { WebsiteTypeField } from "@/components/forms/WebsiteTypeField";
import { BusinessDetailsFields } from "@/components/forms/BusinessDetailsFields";
import { BrandingFields } from "@/components/forms/BrandingFields";
import { ContactFields } from "@/components/forms/ContactFields";
import { SocialMediaFields } from "@/components/forms/SocialMediaFields";
import { LogoUploadField } from "@/components/forms/LogoUploadField";

const formSchema = z.object({
  businessName: z.string().min(2, "Business name must be at least 2 characters"),
  websiteType: z.enum(["cv", "landing", "professional", "bespoke", "other"]),
  keyFeatures: z.string().min(10, "Please describe your key features in more detail"),
  targetAudience: z.string().min(10, "Please describe your target audience in more detail"),
  brandStyle: z.string().min(10, "Please describe your brand style in more detail"),
  contentFocus: z.string().min(10, "Please describe your content focus in more detail"),
  callToAction: z.string().min(2, "Call to action must be at least 2 characters"),
  location: z.string().min(2, "Location must be at least 2 characters"),
  contact: z.string().min(5, "Contact information must be at least 5 characters"),
  additionalInfo: z.string().optional(),
  facebook: z.string().url("Please enter a valid URL").optional().or(z.literal("")),
  instagram: z.string().url("Please enter a valid URL").optional().or(z.literal("")),
  linkedin: z.string().url("Please enter a valid URL").optional().or(z.literal("")),
  twitter: z.string().url("Please enter a valid URL").optional().or(z.literal("")),
  contactMethods: z.array(z.string()).min(1, "Please select at least one contact method"),
  logo: z.string().optional(),
});

const WebForm = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      businessName: "",
      websiteType: "professional",
      keyFeatures: "",
      targetAudience: "",
      brandStyle: "",
      contentFocus: "",
      callToAction: "",
      location: "",
      contact: "",
      additionalInfo: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      twitter: "",
      contactMethods: [],
      logo: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    console.log(values);
    toast.success("form submitted successfully! we'll be in touch soon.");
  };

  return (
    <div className="min-h-screen bg-neutral-100">
      <Helmet>
        <title>website form | heyhalo</title>
        <meta name="description" content="create your website using our structured form" />
      </Helmet>
      <Navigation />
      
      <main className="container mx-auto px-4 pt-32 pb-16">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4 font-helvetica bg-gradient-to-r from-[#9b87f5] via-[#D946EF] to-[#61AAF2] bg-clip-text text-transparent">
            create your website
          </h1>
          <p className="text-neutral-600 text-lg mb-8">
            fill out this form to help us understand your website needs. our ai will process your information,
            and our human experts will review and perfect your website before delivery.
          </p>
          
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg border border-purple-100">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <WebsiteTypeField form={form} />
                <BusinessDetailsFields form={form} />
                <BrandingFields form={form} />
                <LogoUploadField form={form} />
                <SocialMediaFields form={form} />
                <ContactFields form={form} />

                <Button 
                  type="submit"
                  className="w-full bg-gradient-to-r from-[#9b87f5] to-[#D946EF] text-white hover:opacity-90"
                >
                  submit website request
                </Button>
              </form>
            </Form>
          </div>
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default WebForm;