import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

export const BusinessDetailsFields = ({ form }: { form: any }) => {
  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="businessName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>business/project name</FormLabel>
            <FormControl>
              <Input placeholder="enter your business name" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="keyFeatures"
        render={({ field }) => (
          <FormItem>
            <FormLabel>key features</FormLabel>
            <FormControl>
              <Textarea
                placeholder="what are the main features or services you want to highlight?"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="targetAudience"
        render={({ field }) => (
          <FormItem>
            <FormLabel>target audience</FormLabel>
            <FormControl>
              <Textarea
                placeholder="who is your ideal customer or visitor?"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};