import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Clock as ClockIcon } from "lucide-react";
import TypewriterEffect from "./TypewriterEffect";
import HeroFeatures from "./HeroFeatures";
import CountdownClock from "./CountdownClock";

const Hero = () => {
  const navigate = useNavigate();
  
  const phrases = [
    "data entry done",
    "a new resume",
    "ikea furniture built",
    "my lawn mowed",
    "a website built",
    "help moving",
    "house cleaning",
    "my car washed",
    "a handyman",
    "garden maintenance"
  ];

  return (
    <header className="container-padding py-12 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-primary/5 to-transparent" />
      
      <div className="max-w-4xl mx-auto text-center relative z-10 pt-32 pb-24">
        <motion.div
          className="inline-block mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <CountdownClock />
          <div className="mt-4 px-4 py-1.5 rounded-full bg-[#FEF7CD] inline-block">
            <span className="flex items-center gap-2 text-sm font-medium text-[#222222] tracking-widest">
              coming soon <ClockIcon className="w-4 h-4 text-[#222222]" />
            </span>
          </div>
        </motion.div>

        <motion.h1 
          className="heading-xl mb-6 text-secondary leading-normal min-h-[1.5em]"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          i need{" "}
          <TypewriterEffect phrases={phrases} />{" "}
          now!
        </motion.h1>
        
        <motion.div 
          className="flex flex-wrap justify-center gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <button 
            className="button-primary group relative overflow-hidden px-8 py-4 flex items-center gap-2"
            onClick={() => navigate('/chat')}
          >
            <span className="relative z-10">i want it now</span>
            <ArrowRight className="w-5 h-5 relative z-10 group-hover:translate-x-1 transition-transform" />
          </button>
        </motion.div>
        
        <HeroFeatures />
      </div>
    </header>
  );
};

export default Hero;