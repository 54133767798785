import { Link } from "react-router-dom";
import { Mail } from "lucide-react";

const Footer = () => {
  return (
    <footer className="border-t border-primary/10 bg-white">
      <div className="container-padding py-12">
        <div className="max-w-4xl mx-auto text-center">
          <Link to="/" className="flex items-center justify-center gap-2 mb-6">
            <img 
              src="/lovable-uploads/be6780a3-09d3-4e31-b28d-a252c017c222.png" 
              alt="nowyesterday logo" 
              className="h-8"
            />
          </Link>
          <p className="text-neutral-600 text-lg mb-4">
            when you need anything done, now!
          </p>
          <div className="flex items-center justify-center gap-2 text-neutral-600 mb-8">
            <Mail className="w-4 h-4" />
            <span>need anything? email us at <a href="mailto:tomorrow@nowyesterday.com">tomorrow@nowyesterday.com</a></span>
          </div>
          <div className="text-sm text-neutral-600">
            <p>© 2024 nowyesterday. all rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;