import Navigation from "@/components/landing/Navigation";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Check, Clock, Zap, Calendar, Timer } from "lucide-react";
import { Link } from "react-router-dom";

const Pricing = () => {
  const plans = [
    {
      name: "Yesterday",
      price: "Premium Rate", 
      period: "per task",
      description: "Need it done urgently",
      features: [
        "Instant task pickup",
        "Priority matching",
        "24/7 support",
        "Real-time tracking",
        "Guaranteed response"
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      highlighted: true,
      icon: <Zap className="w-6 h-6 text-white" />,
      gradient: "from-[#F97316] via-[#D946EF] to-[#8B5CF6]"
    },
    {
      name: "Today",
      price: "Standard Rate",
      period: "per task",
      description: "Complete within 24 hours",
      features: [
        "Same-day completion",
        "Flexible scheduling",
        "Quality assurance",
        "Regular updates",
        "Support chat"
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      highlighted: false,
      icon: <Clock className="w-6 h-6 text-white" />,
      gradient: "from-[#7EBF8E] via-[#61AAF2] to-[#8989DE]"
    },
    {
      name: "This Week",
      price: "Basic Rate",
      period: "per task",
      description: "Planned completion this week",
      features: [
        "Weekly scheduling",
        "Cost-effective",
        "Progress tracking",
        "Email updates",
        "Basic support"
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      highlighted: false,
      icon: <Calendar className="w-6 h-6 text-white" />,
      gradient: "from-[#D3E4FD] via-[#33C3F0] to-[#0EA5E9]"
    },
    {
      name: "Custom Timeline",
      price: "Quote",
      period: "",
      description: "Flexible scheduling options",
      features: [
        "Custom scheduling",
        "Project planning",
        "Milestone tracking",
        "Dedicated support",
        "Custom solutions",
        "Budget planning"
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      highlighted: false,
      icon: <Timer className="w-6 h-6 text-white" />,
      gradient: "from-[#8B5CF6] via-[#D946EF] to-[#F97316]"
    }
  ];

  return (
    <div className="min-h-screen bg-neutral-100">
      <Helmet>
        <title>Pricing | NowYesterday</title>
        <meta name="description" content="Choose your task completion timeline with NowYesterday" />
      </Helmet>
      <Navigation />
      
      <main className="container mx-auto px-4 py-24 min-h-[80vh]">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 
            className="heading-xl mb-6 bg-gradient-to-r from-[#8B5CF6] via-[#D946EF] to-[#F97316] 
                       bg-clip-text text-transparent animate-gradient bg-[length:200%_auto]"
          >
            Choose Your Timeline
          </h1>
          <p className="text-xl text-neutral-600 max-w-2xl mx-auto">
            From urgent tasks to planned projects, we've got you covered. Select your preferred timeline and let's get things done.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          {plans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`relative group rounded-2xl p-8 transition-all duration-300 hover:scale-105 ${
                plan.highlighted 
                  ? 'bg-gradient-to-br from-white via-[#D3E4FD] to-[#33C3F0] shadow-xl' 
                  : 'bg-white hover:shadow-xl shadow-lg'
              }`}
            >
              <div className={`absolute inset-0 bg-gradient-to-br ${plan.gradient} opacity-0 group-hover:opacity-5 rounded-2xl transition-opacity duration-300`} />
              
              <div className="relative z-10">
                <div className="flex items-center justify-between mb-8">
                  <div className={`w-12 h-12 rounded-xl bg-gradient-to-br ${plan.gradient} flex items-center justify-center`}>
                    {plan.icon}
                  </div>
                  {plan.highlighted && (
                    <span className="px-3 py-1 text-xs font-medium text-white bg-accent-purple rounded-full">
                      Popular
                    </span>
                  )}
                </div>

                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <div className="flex items-baseline gap-1 mb-4">
                  <span className="text-3xl font-bold">{plan.price}</span>
                  <span className="text-neutral-600">{plan.period}</span>
                </div>
                
                <p className="text-neutral-600 mb-6">{plan.description}</p>
                
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center gap-2 text-neutral-600">
                      <Check className="w-5 h-5 text-accent-green flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>

                <Link
                  to={plan.buttonLink}
                  className={`block w-full py-3 px-6 rounded-lg text-center font-medium transition-all duration-200 ${
                    plan.highlighted
                      ? 'bg-primary text-white hover:opacity-90'
                      : 'bg-neutral-200 hover:bg-neutral-300'
                  }`}
                >
                  {plan.buttonText}
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Pricing;