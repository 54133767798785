import Navigation from "@/components/landing/Navigation";
import Footer from "@/components/landing/Footer";
import { Helmet } from "react-helmet";

const Terms = () => {
  return (
    <div className="min-h-screen bg-neutral-100">
      <Helmet>
        <title>Terms of Service | NowYesterday</title>
        <meta name="description" content="Terms of service for NowYesterday task marketplace" />
      </Helmet>
      <Navigation />
      
      <main className="container mx-auto px-4 pt-32 pb-16">
        <h1 className="heading-lg mb-8">Terms of Service</h1>
        <div className="prose prose-lg max-w-none">
          <p>Coming soon...</p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Terms;