import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const CountdownClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Calculate reverse degrees (clockwise to counterclockwise)
  const secondsDegrees = 360 - ((time.getSeconds() / 60) * 360);
  const minutesDegrees = 360 - (((time.getMinutes() + time.getSeconds() / 60) / 60) * 360);
  const hoursDegrees = 360 - (((time.getHours() + time.getMinutes() / 60) / 12) * 360);

  return (
    <div className="flex flex-col items-center">
      <motion.div 
        className="relative w-48 h-48 rounded-full bg-gradient-to-r from-[#FFE783] via-[#FFE783] to-[#FFE783] shadow-lg"
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Clock face */}
        <div className="absolute inset-2 rounded-full bg-white/90 shadow-inner">
          {/* Clock hands */}
          <motion.div
            className="absolute w-0.5 h-16 bg-[#2A4E72] rounded-full"
            style={{
              transformOrigin: 'bottom center',
              left: 'calc(50% - 1px)',
              bottom: '50%',
              transform: `rotate(${hoursDegrees}deg)`,
            }}
            animate={{ rotate: hoursDegrees }}
            transition={{ duration: 0.5, type: "tween" }}
          />
          <motion.div
            className="absolute w-0.5 h-20 bg-[#2A4E72] rounded-full"
            style={{
              transformOrigin: 'bottom center',
              left: 'calc(50% - 1px)',
              bottom: '50%',
              transform: `rotate(${minutesDegrees}deg)`,
            }}
            animate={{ rotate: minutesDegrees }}
            transition={{ duration: 0.5, type: "tween" }}
          />
          <motion.div
            className="absolute w-[1px] h-24 bg-[#2A4E72] rounded-full"
            style={{
              transformOrigin: 'bottom center',
              left: 'calc(50% - 0.5px)',
              bottom: '50%',
              transform: `rotate(${secondsDegrees}deg)`,
            }}
            animate={{ rotate: secondsDegrees }}
            transition={{ duration: 0.5, type: "tween" }}
          />
          
          {/* Center dot */}
          <div className="absolute w-2 h-2 bg-[#2A4E72] rounded-full" style={{ 
            left: 'calc(50% - 4px)', 
            top: 'calc(50% - 4px)' 
          }} />
        </div>
      </motion.div>
    </div>
  );
};

export default CountdownClock;