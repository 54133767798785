import { useState } from "react";
import { Menu } from "lucide-react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed top-4 left-1/2 -translate-x-1/2 w-[95%] max-w-6xl z-50">
      <div className="rounded-full px-6 py-4 flex items-center justify-between shadow-lg bg-gradient-to-r from-[#FFE783] via-[#FFE783] to-[#FFE783] bg-[length:200%_auto] animate-gradient">
        <div className="w-6" /> {/* Spacer for centering */}
        <Link to="/" className="flex items-center justify-center">
          <img 
            src="/lovable-uploads/be6780a3-09d3-4e31-b28d-a252c017c222.png" 
            alt="nowyesterday logo" 
            className="h-8 md:h-10"
          />
        </Link>
        
        <button 
          className="p-2 hover:bg-secondary/10 rounded-full transition-colors"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Menu className="w-6 h-6 text-accent" />
        </button>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <motion.div 
          className="glass-panel mt-2 rounded-xl p-4 shadow-lg bg-gradient-to-r from-[#FFE783] via-[#FFE783] to-[#FFE783] bg-[length:200%_auto] animate-gradient"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="flex flex-col gap-4">
            <Link 
              to="/chat" 
              className="px-6 py-3 font-medium tracking-wide rounded-lg text-center
                       bg-background text-accent hover:bg-accent hover:text-white transition-all duration-200"
            >
              now!
            </Link>
          </div>
        </motion.div>
      )}
    </nav>
  );
};

export default Navigation;