import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import { motion } from "framer-motion";

const formSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters"),
  email: z.string().email("Please enter a valid email"),
  mobile: z.string().min(10, "Please enter a valid mobile number"),
  services: z.string().min(10, "Please tell us about your services (minimum 10 characters)"),
  marketing: z.boolean()
});

const ServiceProviderForm = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      mobile: "",
      services: "",
      marketing: true,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      // Log the form submission with the destination email
      console.log("Form submitted to tomorrow@nowyesterday.com:", values);
      
      // Here you would typically send the form data to your backend
      // For now, we'll just simulate the submission
      toast.success("Thanks for registering! We'll get back to you soon.");
      form.reset();
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-secondary">name</FormLabel>
              <FormControl>
                <Input 
                  placeholder="your name" 
                  {...field} 
                  className="border-secondary/20 focus:border-secondary"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-secondary">email</FormLabel>
              <FormControl>
                <Input 
                  type="email" 
                  placeholder="your@email.com" 
                  {...field}
                  className="border-secondary/20 focus:border-secondary"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="mobile"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-secondary">mobile number</FormLabel>
              <FormControl>
                <Input 
                  type="tel" 
                  placeholder="your mobile number" 
                  {...field}
                  className="border-secondary/20 focus:border-secondary"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="services"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-secondary">what services do you offer?</FormLabel>
              <FormControl>
                <Textarea 
                  placeholder="tell us about your skills and services..."
                  className="min-h-[120px] border-secondary/20 focus:border-secondary"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="marketing"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-sm text-secondary">
                  i consent to receiving marketing communications from nowyesterday and receiving follow up contact
                </FormLabel>
              </div>
            </FormItem>
          )}
        />

        <Button 
          type="submit"
          className="w-full bg-background text-secondary hover:bg-background/90 border border-secondary"
        >
          register your interest
        </Button>
      </form>
    </Form>
  );
};

export default ServiceProviderForm;
