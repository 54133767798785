import { Check } from "lucide-react";
import { motion } from "framer-motion";

const HeroFeatures = () => {
  return (
    <motion.div
      className="mt-12 flex flex-wrap justify-center gap-8 text-sm text-neutral-500"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.6 }}
    >
    </motion.div>
  );
};

export default HeroFeatures;