import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";

export const BrandingFields = ({ form }: { form: any }) => {
  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="brandStyle"
        render={({ field }) => (
          <FormItem>
            <FormLabel>brand style</FormLabel>
            <FormControl>
              <Textarea
                placeholder="describe your preferred design style (e.g., modern, traditional, minimalist)"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="contentFocus"
        render={({ field }) => (
          <FormItem>
            <FormLabel>content focus</FormLabel>
            <FormControl>
              <Textarea
                placeholder="what type of content do you want to emphasize?"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="callToAction"
        render={({ field }) => (
          <FormItem>
            <FormLabel>main call-to-action</FormLabel>
            <FormControl>
              <Input
                placeholder="what action do you want visitors to take?"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};