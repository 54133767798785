import React from "react";
import { motion } from "framer-motion";
import Navigation from "../landing/Navigation";
import Footer from "../landing/Footer";
import { Mail } from "lucide-react";

const ChatOptions = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-neutral-100 to-white flex flex-col">
      <Navigation />
      
      <main className="flex-grow container mx-auto max-w-4xl px-4 pt-32 pb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <div className="mb-8">
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="w-16 h-16 bg-accent rounded-full flex items-center justify-center mx-auto mb-6"
            >
              <Mail className="w-8 h-8 text-secondary" />
            </motion.div>
            
            <motion.h1 
              className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-secondary via-secondary/80 to-secondary bg-clip-text text-transparent"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              Want to contact us about anything?
            </motion.h1>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="glass-panel p-8 rounded-2xl max-w-xl mx-auto"
          >
            <a 
              href="mailto:tomorrow@nowyesterday.com" 
              className="inline-block text-lg md:text-xl font-semibold hover:scale-105 transition-transform duration-200 relative group"
            >
              <span className="relative z-10">tomorrow@nowyesterday.com</span>
              <div className="absolute inset-0 bg-accent/30 -z-10 rounded-lg transform scale-105 opacity-0 group-hover:opacity-100 transition-all duration-200" />
            </a>
          </motion.div>
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default ChatOptions;