import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { motion } from "framer-motion";
import { MessageSquare, Mail, Wrench, Users, Star } from "lucide-react";

const inquiryTypes = [
  { id: "referral", label: "referral code request (coming soon)", icon: Users, description: "get a referral code to share with others" },
  { id: "services", label: "services (coming soon)", icon: Star, description: "request any service you need" },
  { id: "general", label: "general question", icon: MessageSquare, description: "ask us anything about nowyesterday" },
  { id: "support", label: "technical support (coming soon)", icon: Wrench, description: "get help with technical issues" },
  { id: "feedback", label: "feedback", icon: Mail, description: "share your thoughts with us" }
];

export const InquiryTypeSelector = ({ form }: { form: any }) => {
  return (
    <FormField
      control={form.control}
      name="inquiryTypes"
      render={() => (
        <FormItem>
          <div className="mb-4">
            <FormLabel className="text-lg font-bold">what can we help you with?</FormLabel>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {inquiryTypes.map((type) => (
              <FormField
                key={type.id}
                control={form.control}
                name="inquiryTypes"
                render={({ field }) => {
                  const Icon = type.icon;
                  return (
                    <FormItem key={type.id} className="flex flex-col space-y-0">
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className={`
                          relative flex items-center p-4 rounded-xl cursor-pointer
                          ${field.value?.includes(type.id) 
                            ? 'bg-gradient-to-r from-[#9b87f5] to-[#8B5CF6] text-white shadow-lg'
                            : 'bg-white hover:bg-neutral-50 border border-neutral-200'
                          }
                          transition-all duration-200
                        `}
                        onClick={() => {
                          const currentValue = field.value || [];
                          const newValue = currentValue.includes(type.id)
                            ? currentValue.filter((value: string) => value !== type.id)
                            : [...currentValue, type.id];
                          field.onChange(newValue);
                        }}
                      >
                        <div className="flex items-center gap-4">
                          <div className={`
                            p-2 rounded-lg
                            ${field.value?.includes(type.id) 
                              ? 'bg-white/20' 
                              : 'bg-neutral-100'
                            }
                          `}>
                            <Icon className={`w-5 h-5 ${
                              field.value?.includes(type.id) 
                                ? 'text-white' 
                                : 'text-neutral-700'
                            }`} />
                          </div>
                          <div>
                            <p className={`font-medium ${
                              field.value?.includes(type.id) 
                                ? 'text-white' 
                                : 'text-neutral-900'
                            }`}>
                              {type.label}
                            </p>
                            <p className={`text-sm ${
                              field.value?.includes(type.id) 
                                ? 'text-white/90' 
                                : 'text-neutral-500'
                            }`}>
                              {type.description}
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    </FormItem>
                  );
                }}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};